<template>
<b-card-actions action-collapse title="Contact Form">  
    <validation-observer ref="phonebookRules">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group>
                        <label>ประเภทผู้ติดต่อ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="business_type"
                        >
                            <v-select
                                placeholder="Select Type"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="Type"
                                v-model="Data.business_type"
                                :options="business_types"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>ประเภท</label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="customer_type"
                        >
                            <b-form-radio-group
                                id="customer_type"
                                v-model="Data.customer_type"
                                :options="types"
                                name="flavour-1"
                            ></b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>เครดิต(วัน): </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="credits"
                        >
                            <b-form-input
                                v-model="Data.credits"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>รหัสผู้ติดต่อ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="contact_id"
                        >
                            <b-form-input
                                v-model="Data.contact_id"
                                placeholder="รหัสผู้ติดต่อ"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>ชื่อธุรกิจ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="company_name"
                        >
                            <b-form-input
                                v-model="Data.company_name"
                                placeholder="Company Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>ประเภทธุรกิจ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="service"
                        >
                            <v-select
                                placeholder="Select Service"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                v-model="Data.service"
                                :reduce="(option) => option.id"
                                label="title"
                                :options="serviceData.map((g) => ({
                                    title: g.name,
                                    id: g.id,
                                }))"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>เลขบัตรประชาชน: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="tax_id"
                        >
                            <b-form-input
                                v-model="Data.tax_id"
                                placeholder="เลขบัตรประชาชน"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>สำนักงาน/สาขา: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="branch"
                        >
                            <b-form-radio-group
                                id="radio-group-1"
                                v-model="Data.branch"
                                :options="branches"
                                name="radio-options"
                            ></b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>ที่อยู่: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="address"
                        >
                            <b-form-textarea v-model="Data.address"></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>เบอร์โทรสำนักงาน: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="phone"
                        >
                            <b-form-input
                                v-model="Data.phone"
                                placeholder="เลขบัตรประชาชน"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>เบอร์โทรสาร: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="fax"
                        >
                            <b-form-input
                                v-model="Data.fax"
                                placeholder="เบอร์โทรสาร"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>เว็บไซต์: </label>
                        <validation-provider
                        #default="{ errors }"
                        rules="min:0"
                        name="website"
                        >
                        <b-form-input
                            v-model="Data.website"
                            placeholder="เว็บไซต์"
                        />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group>
                        <label>ชื่อผู้ติดต่อ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="contact_name"
                        >
                            <b-form-input
                                v-model="Data.contact_name"
                                placeholder="ชื่อผู้ติดต่อ"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>อีเมล: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="email"
                        >
                        <b-form-input
                            v-model="Data.email"
                            placeholder="อีเมล"
                        />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>เบอร์มือถือ: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="mobile"
                        >
                            <b-form-input
                                v-model="Data.mobile"
                                placeholder="เบอร์มือถือ"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <label>โน๊ต: </label>
                        <validation-provider
                            #default="{ errors }"
                            rules="min:0"
                            name="comment"
                        >
                            <b-form-textarea v-model="Data.comment"></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col> 
                <b-col md="12">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                        Submit
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</b-card-actions>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup, BTable,BPagination,BFormRadio,BFormRadioGroup
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { ref, onMounted, watch } from "@vue/composition-api";
import store from '@/store'
import phonebookStoreModule from '../phonebookStoreModule'

export default {
    components: {
        BCardActions,
        VueAutosuggest,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormRating,
        vSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        BFormRadio,BFormRadioGroup,
        BTable,
        BPagination
    },

    directives: {
        Ripple,
    },

    data(){
        return {
            business_types:['บุคคลธรรมดา','นิติบุคคล'],
            types:['ลูกค้า','ผู้จำหน่าย'],
            branches:['สำนักงานใหญ่','สาขา'],
        }
    },

    setup() {
        const GROUP_PHONEBOOK_APP_STORE_MODULE_NAME = 'app-phone-book'
        if (!store.hasModule(GROUP_PHONEBOOK_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_PHONEBOOK_APP_STORE_MODULE_NAME, phonebookStoreModule)
        
        const userStorage = JSON.parse(localStorage.getItem('userData'))
        const blankData = ref({
            business_type: 'บุคคลธรรมดา',
            service: null,
            customer_type: '',
            credits: '',
            contact_id: '',
            company_name: '',
            tax_id: '',
            branch: 'สำนักงานใหญ่',
            address: '',
            phone: '',
            fax: '',
            website: '',
            contact_name: '',
            email: '',
            mobile: '',
            comment: '',
            created_by: userStorage.id,
        })

        const Data = ref(blankData)
        const serviceData = ref([])
        const phoneBookId = ref(router.currentRoute.params.id)

        watch(() => router.currentRoute.value?.params?.id, (newId) => {
                phoneBookId.value = newId;
                getData();
            }
        );

        const getData = async function () {
            if(phoneBookId.value){
                store
                    .dispatch("app-phone-book/fetchData", {
                        id: phoneBookId.value
                    })
                    .then((response) => {
                        Data.value = response.data.data[0]
                    }).catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })
            }
        }

        const getService = async function () {
            store
                .dispatch("app-phone-book/fetchPhonebookServices")
                .then((response) => {
                    serviceData.value = response.data.data
                }).catch((error) => {
                    this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                })
        }

        onMounted(() => {
            getService();
            getData();
        });

        const onSubmit = async function () {
            if(!phoneBookId.value){
                store
                    .dispatch("app-phone-book/addPhoneBook", Data.value)
                    .then((res) => {
                            this.$router.push('/phonebook/lists', () => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Done`,
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                        text: `You have successfully created.`,
                                    },
                                })
                            });
                        })
                    .catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })
            } else {
                Data.value.updated_by = userStorage.id

                store
                    .dispatch("app-phone-book/updatePhoneBook", {
                        id: phoneBookId.value,
                        data: Data.value
                    })
                    .then((res) => {
                            this.$router.push('/phonebook/lists', () => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Done`,
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                        text: `You have successfully updated.`,
                                    },
                                })
                            })
                        })
                    .catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })
            }
        }

        return {
            onSubmit,
            Data,
            userStorage,
            getService,
            serviceData,
            phoneBookId,
            getData,
        }

    },

    methods: {
        async validationForm() {
            this.$refs.phonebookRules.validate().then(success => {
                if(success){
                    this.onSubmit()
                }
            });
        },

        makeToast(variant = null, title, message) {
            this.$bvToast.toast(message, {
                title: title,
                variant,
                solid: true,
            })
        },
    }
}   
</script>