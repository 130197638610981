var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{attrs:{"action-collapse":"","title":"Contact Form"}},[_c('validation-observer',{ref:"phonebookRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("ประเภทผู้ติดต่อ: ")]),_c('validation-provider',{attrs:{"rules":"required","name":"business_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Select Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"Type","options":_vm.business_types},model:{value:(_vm.Data.business_type),callback:function ($$v) {_vm.$set(_vm.Data, "business_type", $$v)},expression:"Data.business_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("ประเภท")]),_c('validation-provider',{attrs:{"rules":"required","name":"customer_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"customer_type","options":_vm.types,"name":"flavour-1"},model:{value:(_vm.Data.customer_type),callback:function ($$v) {_vm.$set(_vm.Data, "customer_type", $$v)},expression:"Data.customer_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("เครดิต(วัน): ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"credits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.Data.credits),callback:function ($$v) {_vm.$set(_vm.Data, "credits", $$v)},expression:"Data.credits"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("รหัสผู้ติดต่อ: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"contact_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"รหัสผู้ติดต่อ"},model:{value:(_vm.Data.contact_id),callback:function ($$v) {_vm.$set(_vm.Data, "contact_id", $$v)},expression:"Data.contact_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("ชื่อธุรกิจ: ")]),_c('validation-provider',{attrs:{"rules":"required","name":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Company Name"},model:{value:(_vm.Data.company_name),callback:function ($$v) {_vm.$set(_vm.Data, "company_name", $$v)},expression:"Data.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("ประเภทธุรกิจ: ")]),_c('validation-provider',{attrs:{"rules":"required","name":"service"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Select Service","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.id; },"label":"title","options":_vm.serviceData.map(function (g) { return ({
                                    title: g.name,
                                    id: g.id,
                                }); })},model:{value:(_vm.Data.service),callback:function ($$v) {_vm.$set(_vm.Data, "service", $$v)},expression:"Data.service"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("เลขบัตรประชาชน: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"tax_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"เลขบัตรประชาชน"},model:{value:(_vm.Data.tax_id),callback:function ($$v) {_vm.$set(_vm.Data, "tax_id", $$v)},expression:"Data.tax_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("สำนักงาน/สาขา: ")]),_c('validation-provider',{attrs:{"rules":"required","name":"branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-1","options":_vm.branches,"name":"radio-options"},model:{value:(_vm.Data.branch),callback:function ($$v) {_vm.$set(_vm.Data, "branch", $$v)},expression:"Data.branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("ที่อยู่: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.Data.address),callback:function ($$v) {_vm.$set(_vm.Data, "address", $$v)},expression:"Data.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("เบอร์โทรสำนักงาน: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"เลขบัตรประชาชน"},model:{value:(_vm.Data.phone),callback:function ($$v) {_vm.$set(_vm.Data, "phone", $$v)},expression:"Data.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("เบอร์โทรสาร: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"เบอร์โทรสาร"},model:{value:(_vm.Data.fax),callback:function ($$v) {_vm.$set(_vm.Data, "fax", $$v)},expression:"Data.fax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("เว็บไซต์: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"เว็บไซต์"},model:{value:(_vm.Data.website),callback:function ($$v) {_vm.$set(_vm.Data, "website", $$v)},expression:"Data.website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("ชื่อผู้ติดต่อ: ")]),_c('validation-provider',{attrs:{"rules":"required","name":"contact_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"ชื่อผู้ติดต่อ"},model:{value:(_vm.Data.contact_name),callback:function ($$v) {_vm.$set(_vm.Data, "contact_name", $$v)},expression:"Data.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("อีเมล: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"อีเมล"},model:{value:(_vm.Data.email),callback:function ($$v) {_vm.$set(_vm.Data, "email", $$v)},expression:"Data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("เบอร์มือถือ: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"เบอร์มือถือ"},model:{value:(_vm.Data.mobile),callback:function ($$v) {_vm.$set(_vm.Data, "mobile", $$v)},expression:"Data.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("โน๊ต: ")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.Data.comment),callback:function ($$v) {_vm.$set(_vm.Data, "comment", $$v)},expression:"Data.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }